<template>
	<div>
		<Contacts0></Contacts0>
	</div>
</template>

<script type="text/javascript">
import Contacts0 from '@/components/Contacts'

export default {
	name: 'Contacts',
	components: {
		Contacts0,
	}
}
	
</script>

